@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./resources/fonts/Montserrat/Bold/Montserrat-Bold.eot');
  src: url('./resources/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
       url('./resources/fonts/Montserrat/Bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg'),
       url('./resources/fonts/Montserrat/Bold/Montserrat-Bold.ttf') format('truetype'),
       url('./resources/fonts/Montserrat/Bold/Montserrat-Bold.woff') format('woff'),
       url('./resources/fonts/Montserrat/Bold/Montserrat-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('./resources/fonts/OpenSans/Regular/OpenSans-Regular.eot');
  src: url('./resources/fonts/OpenSans/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('./resources/fonts/OpenSans/Regular/OpenSans-Regular.svg#OpenSans-Regular') format('svg'),
       url('./resources/fonts/OpenSans/Regular/OpenSans-Regular.ttf') format('truetype'),
       url('./resources/fonts/OpenSans/Regular/OpenSans-Regular.woff') format('woff'),
       url('./resources/fonts/OpenSans/Regular/OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "OpenSans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #fff!important;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}