html,
body,
#root {
  height: 100%
}

.wrapper {
  height: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  font-size: 35px;
  padding: 20px 0;
}

.footer {
  text-align: center;
  padding: 10px 0;
}

.displayErrors input:invalid {
  transform: scaleX(1);
  border-bottom: 2px solid #f44336;
  padding-bottom: 5px;
}