@font-face {
  font-family: 'Montserrat-Bold';
  src: url(/static/media/Montserrat-Bold.054d042c.eot);
  src: url(/static/media/Montserrat-Bold.054d042c.eot?#iefix) format('embedded-opentype'),
       url(/static/media/Montserrat-Bold.39df58e2.svg#Montserrat-Bold) format('svg'),
       url(/static/media/Montserrat-Bold.a49c6fe0.ttf) format('truetype'),
       url(/static/media/Montserrat-Bold.f5ab32b7.woff) format('woff'),
       url(/static/media/Montserrat-Bold.4887637e.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url(/static/media/OpenSans-Regular.3bcacc0b.eot);
  src: url(/static/media/OpenSans-Regular.3bcacc0b.eot?#iefix) format('embedded-opentype'),
       url(/static/media/OpenSans-Regular.53c1d286.svg#OpenSans-Regular) format('svg'),
       url(/static/media/OpenSans-Regular.8563553f.ttf) format('truetype'),
       url(/static/media/OpenSans-Regular.bc3dcd61.woff) format('woff'),
       url(/static/media/OpenSans-Regular.4f9c870c.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "OpenSans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #fff!important;
  -webkit-box-shadow: none;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
@-webkit-keyframes circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(.5) skew(1deg);
    -webkit-opacity: .1
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-opacity: .5
  }

  100% {
    -webkit-transform: rotate(0deg) scale(.5) skew(1deg);
    -webkit-opacity: .1
  }
}

@keyframes circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(.5) skew(1deg);
            transform: rotate(0deg) scale(.5) skew(1deg);
    opacity: .1
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
            transform: rotate(0deg) scale(1) skew(1deg);
    opacity: .5
  }

  100% {
    -webkit-transform: rotate(0deg) scale(.5) skew(1deg);
            transform: rotate(0deg) scale(.5) skew(1deg);
    opacity: .1
  }
}
html,
body,
#root {
  height: 100%
}

.wrapper {
  height: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  font-size: 35px;
  padding: 20px 0;
}

.footer {
  text-align: center;
  padding: 10px 0;
}

.displayErrors input:invalid {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  border-bottom: 2px solid #f44336;
  padding-bottom: 5px;
}
