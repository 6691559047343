@-moz-keyframes circle-anim {
  0% {
    -moz-transform: rotate(0deg) scale(.5) skew(1deg);
    opacity: .1;
    -moz-opacity: .1;
    -webkit-opacity: .1;
    -o-opacity: .1
  }

  50% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: .6;
    -moz-opacity: .6;
    -webkit-opacity: .6;
    -o-opacity: .6
  }

  100% {
    -moz-transform: rotate(0deg) scale(.5) skew(1deg);
    opacity: .1;
    -moz-opacity: .1;
    -webkit-opacity: .1;
    -o-opacity: .1
  }
}

@-webkit-keyframes circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(.5) skew(1deg);
    -webkit-opacity: .1
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-opacity: .5
  }

  100% {
    -webkit-transform: rotate(0deg) scale(.5) skew(1deg);
    -webkit-opacity: .1
  }
}

@-o-keyframes circle-anim {
  0% {
    -o-transform: rotate(0deg) scale(.5) skew(1deg);
    -o-opacity: .1
  }

  50% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    -o-opacity: .5
  }
  100% {
    -o-transform: rotate(0deg) scale(.5) skew(1deg);
    -o-opacity: .1
  }
}

@keyframes circle-anim {
  0% {
    transform: rotate(0deg) scale(.5) skew(1deg);
    opacity: .1
  }

  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: .5
  }

  100% {
    transform: rotate(0deg) scale(.5) skew(1deg);
    opacity: .1
  }
}